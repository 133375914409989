var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container add-global-modal-main-container"},[(!_vm.secretInformation)?_c('h1',{staticClass:"add-global-modal-main-title"},[_vm._v(" Agregar secreto ")]):_vm._e(),(!_vm.secretInformation)?_c('div',{staticClass:"global-form-hint-container"},[_c('p',{staticClass:"global-form-hint"},[_vm._v("Los campos requeridos tienen el marco")]),_c('span',{staticClass:"global-form-hint-color"},[_vm._v(" Morado ")])]):_vm._e(),_c('h3',{staticClass:"form-division"},[_vm._v("Información general")]),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Descripción del secreto (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.secretRequiredErrors.DESCRIPCION },"message":{
        'La descripción del secreto no es válida':
          _vm.secretRequiredErrors.DESCRIPCION,
      }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: Lista de precios mayoreo","expanded":"","icon":"numeric","required":""},model:{value:(_vm.newSecret.DESCRIPCION),callback:function ($$v) {_vm.$set(_vm.newSecret, "DESCRIPCION", $$v)},expression:"newSecret.DESCRIPCION"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Secreto (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.secretRequiredErrors.SECRETO },"message":{
        'El secreto no es válido': _vm.secretRequiredErrors.SECRETO,
      }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 1234MAYOREO_2SO21","expanded":"","icon":"form-textbox-password","type":"password","password-reveal":"","required":""},model:{value:(_vm.newSecret.SECRETO),callback:function ($$v) {_vm.$set(_vm.newSecret, "SECRETO", $$v)},expression:"newSecret.SECRETO"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{staticClass:"required-input",attrs:{"label":"Lista de precios (Requerido)","expanded":"","label-position":"inside","required":"","type":{ 'is-danger': _vm.secretRequiredErrors.LISTA_PRECIOS },"message":{
        'La lista de precios del secreto no es valida':
          _vm.secretRequiredErrors.LISTA_PRECIOS,
      }}},[_c('b-select',{attrs:{"placeholder":"Ejemplo: Martillo Almacen general","expanded":"","icon":"format-list-bulleted"},model:{value:(_vm.newSecret.LISTA_PRECIOS),callback:function ($$v) {_vm.$set(_vm.newSecret, "LISTA_PRECIOS", $$v)},expression:"newSecret.LISTA_PRECIOS"}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Seleccione una lista de precios")]),_vm._l((_vm.priceLists),function(singlePriceList){return _c('option',{key:singlePriceList.CLAVE_LISTAP,domProps:{"value":singlePriceList._id}},[_vm._v(" "+_vm._s(singlePriceList.CLAVE_LISTAP)+" ")])})],2)],1)],1),(_vm.secretInformation)?_c('div',{staticClass:"add-global-controls"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.saveSecret(1)}}},[_vm._v("Actualizar secreto")])],1):_vm._e(),(!_vm.secretInformation)?_c('div',{staticClass:"add-global-controls"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.saveSecret(0)}}},[_vm._v("Añadir secreto")]),_c('b-button',{staticClass:"cancel-button",attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Regresar")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }