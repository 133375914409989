var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container add-global-modal-main-container"},[_c('h1',{staticClass:"add-global-modal-main-title"},[_vm._v("Historial de cortes de caja")]),_c('div',{staticClass:"global-lists-filters-container"},[_c('b-field',{staticClass:"no-margin",attrs:{"label":"Fecha de inicio","label-position":"inside","type":{ 'is-danger': _vm.errors.startDate },"message":{
        'La fecha de inicio no es valida': _vm.errors.startDate,
      }}},[_c('b-datepicker',{staticClass:"global-lists-filters-control",attrs:{"date-formatter":function (date) { return _vm.moment(date).format('DD/MM/YYYY'); },"day-names":['D', 'L', 'M', 'M', 'J', 'V', 'S'],"month-names":[
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre' ],"show-week-number":true,"locale":"es-MX","placeholder":"Fecha de inicio","icon":"calendar-today","trap-focus":""},on:{"input":function (value) { return _vm.changeDateFilterValue(value); }},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('b-field',{staticClass:"no-margin",attrs:{"label":"Fecha de fin","label-position":"inside","type":{ 'is-danger': _vm.errors.endDate },"message":{
        'La fecha de fin no es valida': _vm.errors.endDate,
      }}},[_c('b-datepicker',{staticClass:"global-lists-filters-control",attrs:{"date-formatter":function (date) { return _vm.moment(date).format('DD/MM/YYYY'); },"day-names":['D', 'L', 'M', 'M', 'J', 'V', 'S'],"month-names":[
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre' ],"show-week-number":true,"locale":"es-MX","placeholder":"Fecha de fin","icon":"calendar-today","trap-focus":""},on:{"input":function (value) { return _vm.changeDateFilterValue(value); }},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('div',{staticClass:"invoice-div-bottom-div"},[_c('div',{staticClass:"container global-list-main-container"},[_c('b-tabs',{attrs:{"type":"is-boxed"}},[_c('b-tab-item',{staticStyle:{"padding-top":"2rem"},attrs:{"label":"Historial de cortes de caja","icon":"file-chart-outline"}},[_c('PosCortesList',{key:_vm.componentKey,attrs:{"information":_vm.selectedPosInformation}})],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }