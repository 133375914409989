<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Detalles de corte</h1>

    <h3 class="form-division">Saldo inicial</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Saldo inicial (Requerido)"
        expanded
        label-position="inside"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 16"
          :required="false"
          v-model="corte.SALDO_INICIAL"
          :disabled="true"
          icon="account-cash"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <h3 class="form-division">Cantidades reportadas</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de billetes de 1000 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="corte.REPORTADO.CAMBIO.MIL"
          type="number"
          step="any"
          :disabled="true"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de billetes de 500 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.REPORTADO.CAMBIO.QUINIENTOS"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de billetes de 200 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.REPORTADO.CAMBIO.DOSCIENTOS"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de billetes de 100 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.REPORTADO.CAMBIO.CIEN"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de billetes de 50 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.REPORTADO.CAMBIO.CINCUENTA"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de billetes de 20 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.REPORTADO.CAMBIO.VEINTE"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de monedas de 10 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.REPORTADO.CAMBIO.DIEZ"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de monedas de 5 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.REPORTADO.CAMBIO.CINCO"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de monedas de 2 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.REPORTADO.CAMBIO.DOS"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de monedas de 1 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.REPORTADO.CAMBIO.UNO"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de monedas de 0.50 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.REPORTADO.CAMBIO.CINCUENTA_CEN"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Efectivo (Requerido)" expanded label-position="inside">
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          v-model="corte.REPORTADO.EFECTIVO"
          :disabled="true"
          icon="cash"
        >
        </MoneyInput>
      </b-field>
      <b-field
        label="Tarjeta de crédito (Requerido)"
        expanded
        label-position="inside"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          v-model="corte.REPORTADO.TARJETA_DE_CREDITO"
          :disabled="true"
          icon="cash"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Tarjeta de débito (Requerido)"
        expanded
        label-position="inside"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          v-model="corte.REPORTADO.TARJETA_DE_DEBITO"
          :disabled="true"
          icon="cash"
        >
        </MoneyInput>
      </b-field>
      <b-field label="Manuales (Requerido)" expanded label-position="inside">
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          v-model="corte.REPORTADO.MANUALES"
          :disabled="true"
          icon="cash"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Crédito (Requerido)" expanded label-position="inside">
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          v-model="corte.REPORTADO.CREDITO"
          :disabled="true"
          icon="credit-card"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <h3 class="form-division">Cantidades recibida</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de billetes de 1000 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="corte.RECIBIDO.CAMBIO.MIL"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de billetes de 500 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.RECIBIDO.CAMBIO.QUINIENTOS"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de billetes de 200 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.RECIBIDO.CAMBIO.DOSCIENTOS"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de billetes de 100 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.RECIBIDO.CAMBIO.CIEN"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de billetes de 50 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.RECIBIDO.CAMBIO.CINCUENTA"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de billetes de 20 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.RECIBIDO.CAMBIO.VEINTE"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de monedas de 10 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.RECIBIDO.CAMBIO.DIEZ"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de monedas de 5 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.RECIBIDO.CAMBIO.CINCO"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de monedas de 2 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.RECIBIDO.CAMBIO.DOS"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de monedas de 1 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.RECIBIDO.CAMBIO.UNO"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de monedas de 0.50 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.RECIBIDO.CAMBIO.CINCUENTA_CEN"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Efectivo (Requerido)" expanded label-position="inside">
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          v-model="corte.RECIBIDO.EFECTIVO"
          :disabled="true"
          icon="cash"
        >
        </MoneyInput>
      </b-field>

      <b-field
        label="Tarjeta de crédito (Requerido)"
        expanded
        label-position="inside"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          v-model="corte.RECIBIDO.TARJETA_DE_CREDITO"
          :disabled="true"
          icon="cash"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Tarjeta de débito (Requerido)"
        expanded
        label-position="inside"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          v-model="corte.RECIBIDO.TARJETA_DE_DEBITO"
          :disabled="true"
          icon="cash"
        >
        </MoneyInput>
      </b-field>
      <b-field label="Manuales (Requerido)" expanded label-position="inside">
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          v-model="corte.RECIBIDO.MANUALES"
          :disabled="true"
          icon="cash"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Crédito (Requerido)" expanded label-position="inside">
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          v-model="corte.RECIBIDO.CREDITO"
          :disabled="true"
          icon="credit-card"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <h3 class="form-division">Diferencias</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de billetes de 1000 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="corte.DIFERENCIA.CAMBIO.MIL"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de billetes de 500 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.DIFERENCIA.CAMBIO.QUINIENTOS"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de billetes de 200 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.DIFERENCIA.CAMBIO.DOSCIENTOS"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de billetes de 100 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.DIFERENCIA.CAMBIO.CIEN"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de billetes de 50 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.DIFERENCIA.CAMBIO.CINCUENTA"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de billetes de 20 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.DIFERENCIA.CAMBIO.VEINTE"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de monedas de 10 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.DIFERENCIA.CAMBIO.DIEZ"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de monedas de 5 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.DIFERENCIA.CAMBIO.CINCO"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de monedas de 2 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.DIFERENCIA.CAMBIO.DOS"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de monedas de 1 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.DIFERENCIA.CAMBIO.UNO"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de monedas de 0.50 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          :disabled="true"
          v-model="corte.DIFERENCIA.CAMBIO.CINCUENTA_CEN"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Efectivo (Requerido)" expanded label-position="inside">
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          v-model="corte.DIFERENCIA.EFECTIVO"
          :disabled="true"
          icon="cash"
        >
        </MoneyInput>
      </b-field>

      <b-field
        label="Tarjeta de crédito (Requerido)"
        expanded
        label-position="inside"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          v-model="corte.DIFERENCIA.TARJETA_DE_CREDITO"
          :disabled="true"
          icon="cash"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Tarjeta de débito (Requerido)"
        expanded
        label-position="inside"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          v-model="corte.DIFERENCIA.TARJETA_DE_DEBITO"
          :disabled="true"
          icon="cash"
        >
        </MoneyInput>
      </b-field>
      <b-field label="Manuales (Requerido)" expanded label-position="inside">
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          v-model="corte.DIFERENCIA.MANUALES"
          :disabled="true"
          icon="cash"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Crédito (Requerido)" expanded label-position="inside">
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          v-model="corte.DIFERENCIA.CREDITO"
          :disabled="true"
          icon="credit-card"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Observaciones (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Pago realizado el día Martes"
          expanded
          v-model="corte.OBSERVACIONES"
          type="textarea"
        ></b-input>
      </b-field>
    </b-field>

    <div class="add-global-controls">
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

// @ is an alias to /src
export default {
  name: "CloseCorteDetailsModal",
  props: ["posInformation", "corteInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      corte: this.corteInformation,
    };
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
