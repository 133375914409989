<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Cerrar corte</h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Saldo inicial</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Saldo inicial (Requerido)"
        expanded
        label-position="inside"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 16"
          :required="false"
          v-model="corte.SALDO_INICIAL"
          :disabled="true"
          icon="account-cash"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <h3 class="form-division">Cantidades reportadas</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de billetes de 1000 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="newCloseCorte.CAMBIO.MIL"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de billetes de 500 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="newCloseCorte.CAMBIO.QUINIENTOS"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de billetes de 200 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="newCloseCorte.CAMBIO.DOSCIENTOS"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de billetes de 100 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="newCloseCorte.CAMBIO.CIEN"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de billetes de 50 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="newCloseCorte.CAMBIO.CINCUENTA"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de billetes de 20 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="newCloseCorte.CAMBIO.VEINTE"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de monedas de 10 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="newCloseCorte.CAMBIO.DIEZ"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de monedas de 5 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="newCloseCorte.CAMBIO.CINCO"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de monedas de 2 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="newCloseCorte.CAMBIO.DOS"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de monedas de 1 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="newCloseCorte.CAMBIO.UNO"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de monedas de 0.50 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="newCloseCorte.CAMBIO.CINCUENTA_CEN"
          type="number"
          step="any"
          @input="updateCashBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Efectivo (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': closeCorteRequiredErrors.EFECTIVO }"
        :message="{
          'El valor de la cantidad pagada en efectivo no es válido':
            closeCorteRequiredErrors.EFECTIVO,
        }"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          :required="true"
          v-model="newCloseCorte.EFECTIVO"
          icon="cash"
        >
        </MoneyInput>
      </b-field>

      <b-field
        label="Tarjeta de crédito (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': closeCorteRequiredErrors.TARJETA_DE_CREDITO }"
        :message="{
          'El valor de la cantidad pagada en tarjeta de crédito no es válido':
            closeCorteRequiredErrors.TARJETA_DE_CREDITO,
        }"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          :required="true"
          v-model="newCloseCorte.TARJETA_DE_CREDITO"
          icon="cash"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Tarjeta de débito (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': closeCorteRequiredErrors.TARJETA_DE_DEBITO }"
        :message="{
          'El valor de la cantidad pagada en tarjeta de débito no es válido':
            closeCorteRequiredErrors.TARJETA_DE_DEBITO,
        }"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          :required="true"
          v-model="newCloseCorte.TARJETA_DE_DEBITO"
          icon="cash"
        >
        </MoneyInput>
      </b-field>
      <b-field
        label="Manuales (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': closeCorteRequiredErrors.MANUALES }"
        :message="{
          'El valor de la cantidad pagada en manuales no es válido':
            closeCorteRequiredErrors.MANUALES,
        }"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          :required="true"
          v-model="newCloseCorte.MANUALES"
          icon="cash"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <h3 class="form-division">Cantidad reportada en crédito</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Crédito (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': closeCorteRequiredErrors.OTRO }"
        :message="{
          'El valor de la cantidad pagada en crédito no es válido':
            closeCorteRequiredErrors.CREDITO,
        }"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          :required="true"
          v-model="newCloseCorte.CREDITO"
          icon="credit-card"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Observaciones (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Pago realizado el día Martes"
          expanded
          v-model="newCloseCorte.OBSERVACIONES"
          type="textarea"
        ></b-input>
      </b-field>
    </b-field>

    <div class="add-global-controls">
      <b-button type="is-success" @click="closeCorte()">Cerrar corte</b-button>
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import { fillObjectForm } from "../../../utils/component-fns";
import { EventBus } from "../../../event-bus.js";
import { validateFields, checkValidationErrors } from "../../../utils/fns";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

// @ is an alias to /src
export default {
  name: "CloseCorteModal",
  props: ["posInformation", "corteInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      corte: this.corteInformation,
      typeSelected: [],
      newCloseCorte: {
        EFECTIVO: 0,
        CAMBIO: {
          MIL: 0,
          QUINIENTOS: 0,
          DOSCIENTOS: 0,
          CIEN: 0,
          CINCUENTA: 0,
          VEINTE: 0,
          DIEZ: 0,
          CINCO: 0,
          DOS: 0,
          UNO: 0,
          CINCUENTA_CEN: 0,
        },
        CHEQUE: 0,
        TRANSFERENCIAS: 0,
        TARJETA_DE_CREDITO: 0,
        TARJETA_DE_DEBITO: 0,
        POR_DEFINIR: 0,
        OTRO: 0,
        MANUALES: 0,
        CREDITO: 0,
        OBSERVACIONES: "",
      },
      closeCorteRequiredErrors: {
        EFECTIVO: false,
        TARJETA_DE_CREDITO: false,
        TARJETA_DE_DEBITO: false,
        MANUALES: false,
        CREDITO: false,
      },
    };
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves or updates brand in the database depending on type
     */
    async closeCorte() {
      try {
        let paymentMethodsErrorMesage = "";
        let paymentMethodsError = false;

        if (
          this.corteInformation.RECIBIDO["EFECTIVO"] &&
          !this.newCloseCorte.EFECTIVO
        ) {
          paymentMethodsError = true;
          paymentMethodsErrorMesage +=
            paymentMethodsErrorMesage.length === 0 ? "Efectivo" : ", Efectivo";
        }

        if (
          this.corteInformation.RECIBIDO["MANUALES"] &&
          !this.newCloseCorte.MANUALES
        ) {
          paymentMethodsError = true;
          paymentMethodsErrorMesage +=
            paymentMethodsErrorMesage.length === 0
              ? "Movimientos manuales"
              : ", Movimientos manuales";
        }

        if (
          this.corteInformation.RECIBIDO["TARJETA_DE_DEBITO"] &&
          !this.newCloseCorte.TARJETA_DE_DEBITO
        ) {
          paymentMethodsError = true;
          paymentMethodsErrorMesage +=
            paymentMethodsErrorMesage.length === 0
              ? "Tarjeta de débito"
              : ", Tarjeta de débito";
        }

        if (
          this.corteInformation.RECIBIDO["TARJETA_DE_CREDITO"] &&
          !this.newCloseCorte.TARJETA_DE_CREDITO
        ) {
          paymentMethodsError = true;
          paymentMethodsErrorMesage +=
            paymentMethodsErrorMesage.length === 0
              ? "Tarjeta de crédito"
              : ", Tarjeta de crédito";
        }

        if (
          this.corteInformation.RECIBIDO["CREDITO"] &&
          !this.newCloseCorte.CREDITO
        ) {
          paymentMethodsError = true;
          paymentMethodsErrorMesage +=
            paymentMethodsErrorMesage.length === 0 ? "Crédito" : ", Crédito";
        }

        if (paymentMethodsError) {
          this.$buefy.dialog.confirm({
            title: "¿Esta segro que desea continuar?",
            message: `Se tienen registradas una o más ventas en los siguiente métodos de pago: ${paymentMethodsErrorMesage}. Pero se registró 0 en la cantidad reportada en el cierre del corte ¿Esta segro que desea continuar?`,
            confirmText: "Continuar",
            type: "is-success",
            hasIcon: true,
            onConfirm: async () => {
              await this.runCloseCorte();
            },
            cancelText: "Cancelar",
            canCancel: true,
          });
        } else {
          await this.runCloseCorte();
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar el cierre del corte en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     * @desc saves or updates brand in the database depending on type
     */
    async runCloseCorte() {
      try {
        this.closeCorteRequiredErrors = validateFields(
          this.newCloseCorte,
          this.closeCorteRequiredErrors
        );
        if (checkValidationErrors(this.closeCorteRequiredErrors)) {
          let response = await this.$store.dispatch("CLOSEPOS", {
            closeInformation: this.newCloseCorte,
            posId: this.posInformation._id,
          });
          if (response == "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el cierre del corte",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
                EventBus.$emit("closeCorteModalRefresh", {});
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar el cierre del corte en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el cierre del corte en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar el cierre del corte en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    updateCashBalance() {
      let finalCashBalance = 0;

      finalCashBalance += this.newCloseCorte.CAMBIO.MIL * 1000;
      finalCashBalance += this.newCloseCorte.CAMBIO.QUINIENTOS * 500;
      finalCashBalance += this.newCloseCorte.CAMBIO.DOSCIENTOS * 200;
      finalCashBalance += this.newCloseCorte.CAMBIO.CIEN * 100;
      finalCashBalance += this.newCloseCorte.CAMBIO.CINCUENTA * 50;
      finalCashBalance += this.newCloseCorte.CAMBIO.VEINTE * 20;
      finalCashBalance += this.newCloseCorte.CAMBIO.DIEZ * 10;
      finalCashBalance += this.newCloseCorte.CAMBIO.CINCO * 5;
      finalCashBalance += this.newCloseCorte.CAMBIO.DOS * 2;
      finalCashBalance += this.newCloseCorte.CAMBIO.UNO * 1;
      finalCashBalance += this.newCloseCorte.CAMBIO.CINCUENTA_CEN * 0.5;

      this.newCloseCorte.EFECTIVO = finalCashBalance;
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
