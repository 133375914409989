<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Agregar movimiento manual</h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Descripción (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': movementRequiredErrors.DESCRIPCION }"
        :message="{
          'La descripción del movimiento manual no es valido':
            movementRequiredErrors.DESCRIPCION,
        }"
      >
        <b-input
          placeholder="Ejemplo: Clave Pago de llanta de camioneta"
          expanded
          v-model="newMovement.DESCRIPCION"
          required
          icon="clipboard"
        ></b-input>
      </b-field>
      <b-field
        label="Cantidad (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': movementRequiredErrors.CANTIDAD }"
        :message="{
          'La cantidad del movimiento manual no es valido':
            movementRequiredErrors.CANTIDAD,
        }"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          :required="true"
          v-model="newMovement.CANTIDAD"
          icon="cash"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <h3 class="form-division">Cambio</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de billetes de 1000 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="newMovement.CAMBIO.MIL"
          type="number"
          step="any"
          @input="updateQuantity()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de billetes de 500 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="newMovement.CAMBIO.QUINIENTOS"
          type="number"
          step="any"
          @input="updateQuantity()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de billetes de 200 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="newMovement.CAMBIO.DOSCIENTOS"
          type="number"
          step="any"
          @input="updateQuantity()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de billetes de 100 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="newMovement.CAMBIO.CIEN"
          type="number"
          step="any"
          @input="updateQuantity()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de billetes de 50 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="newMovement.CAMBIO.CINCUENTA"
          type="number"
          step="any"
          @input="updateQuantity()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de billetes de 20 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="newMovement.CAMBIO.VEINTE"
          type="number"
          step="any"
          @input="updateQuantity()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de monedas de 10 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="newMovement.CAMBIO.DIEZ"
          type="number"
          step="any"
          @input="updateQuantity()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de monedas de 5 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="newMovement.CAMBIO.CINCO"
          type="number"
          step="any"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de monedas de 2 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="newMovement.CAMBIO.DOS"
          type="number"
          step="any"
          @input="updateQuantity()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de monedas de 1 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="newMovement.CAMBIO.UNO"
          type="number"
          step="any"
          @input="updateQuantity()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de monedas de 0.50 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="newMovement.CAMBIO.CINCUENTA_CEN"
          type="number"
          step="any"
          @input="updateQuantity()"
        ></b-input>
      </b-field>
    </b-field>

    <div class="add-global-controls">
      <b-button type="is-success" @click="addManualMovement()"
        >Añadir movimiento manual</b-button
      >
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../utils/fns";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";
import { fillObjectForm } from "../../../utils/component-fns";
// @ is an alias to /src
export default {
  name: "AddManualMovementModal",
  props: ["posId", "type"],
  components: {},
  directives: { cleave },
  data() {
    return {
      typeSelected: [],
      newMovement: {
        DESCRIPCION: "",
        CANTIDAD: "",
        CAMBIO: {
          MIL: 0,
          QUINIENTOS: 0,
          DOSCIENTOS: 0,
          CIEN: 0,
          CINCUENTA: 0,
          VEINTE: 0,
          DIEZ: 0,
          CINCO: 0,
          DOS: 0,
          UNO: 0,
          CINCUENTA_CEN: 0,
        },
      },
      movementRequiredErrors: {
        DESCRIPCION: false,
        CANTIDAD: false,
      },
    };
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves a new brand in the database, display messages according to the status
     */
    async addManualMovement() {
      try {
        this.movementRequiredErrors = validateFields(
          this.newMovement,
          this.movementRequiredErrors
        );

        // Transform to negative if the type is outcome
        if (this.type === 1) {
          this.newMovement.CANTIDAD *= -1;
          this.newMovement.CAMBIO.MIL *= -1;
          this.newMovement.CAMBIO.QUINIENTOS *= -1;
          this.newMovement.CAMBIO.DOSCIENTOS *= -1;
          this.newMovement.CAMBIO.CIEN *= -1;
          this.newMovement.CAMBIO.CINCUENTA *= -1;
          this.newMovement.CAMBIO.VEINTE *= -1;
          this.newMovement.CAMBIO.DIEZ *= -1;
          this.newMovement.CAMBIO.CINCO *= -1;
          this.newMovement.CAMBIO.DOS *= -1;
          this.newMovement.CAMBIO.UNO *= -1;
          this.newMovement.CAMBIO.CINCUENTA_CEN *= -1;
        }

        if (checkValidationErrors(this.movementRequiredErrors)) {
          let response = await this.$store.dispatch("ADDMANUALMOVEMENT", {
            posId: this.posId,
            newManualMovement: this.newMovement,
          });
          if (response == "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el movimiento manual",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error el movimiento manual en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el movimiento manual en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar el movimiento manual en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    updateQuantity() {
      let finalBalance = 0;

      finalBalance += this.newMovement.CAMBIO.MIL * 1000;
      finalBalance += this.newMovement.CAMBIO.QUINIENTOS * 500;
      finalBalance += this.newMovement.CAMBIO.DOSCIENTOS * 200;
      finalBalance += this.newMovement.CAMBIO.CIEN * 100;
      finalBalance += this.newMovement.CAMBIO.CINCUENTA * 50;
      finalBalance += this.newMovement.CAMBIO.VEINTE * 20;
      finalBalance += this.newMovement.CAMBIO.DIEZ * 10;
      finalBalance += this.newMovement.CAMBIO.CINCO * 5;
      finalBalance += this.newMovement.CAMBIO.DOS * 2;
      finalBalance += this.newMovement.CAMBIO.UNO * 1;
      finalBalance += this.newMovement.CAMBIO.CINCUENTA_CEN * 0.5;

      this.newMovement.CANTIDAD = finalBalance;
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
