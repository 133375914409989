<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Historial de cortes de caja</h1>

    <div class="global-lists-filters-container">
      <b-field
        label="Fecha de inicio"
        label-position="inside"
        class="no-margin"
        :type="{ 'is-danger': errors.startDate }"
        :message="{
          'La fecha de inicio no es valida': errors.startDate,
        }"
      >
        <b-datepicker
          class="global-lists-filters-control"
          @input="(value) => changeDateFilterValue(value)"
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          :show-week-number="true"
          locale="es-MX"
          placeholder="Fecha de inicio"
          icon="calendar-today"
          v-model="startDate"
          trap-focus
        >
        </b-datepicker>
      </b-field>
      <b-field
        label="Fecha de fin"
        label-position="inside"
        class="no-margin"
        :type="{ 'is-danger': errors.endDate }"
        :message="{
          'La fecha de fin no es valida': errors.endDate,
        }"
      >
        <b-datepicker
          class="global-lists-filters-control"
          @input="(value) => changeDateFilterValue(value)"
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          :show-week-number="true"
          locale="es-MX"
          placeholder="Fecha de fin"
          icon="calendar-today"
          v-model="endDate"
          trap-focus
        >
        </b-datepicker>
      </b-field>
    </div>

    <div class="invoice-div-bottom-div">
      <div class="container global-list-main-container">
        <b-tabs type="is-boxed">
          <b-tab-item
            label="Historial de cortes de caja"
            icon="file-chart-outline"
            style="padding-top: 2rem"
          >
            <PosCortesList
              :information="selectedPosInformation"
              :key="componentKey"
            ></PosCortesList>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import PosCortesList from "./PosCortesList";
import { EventBus } from "../../../event-bus.js";
import moment from "moment";

// @ is an alias to /src
export default {
  name: "PosCortes",
  props: ["posInformation"],
  components: {
    PosCortesList,
  },
  data() {
    return {
      componentKey: 0,
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 15
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
    };
  },
  created() {},
  mounted() {
    EventBus.$on("closeCorteModalRefresh", () => {
      this.forceRerender();
    });
  },
  beforeDestroy() {
    EventBus.$off();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    forceRerender() {
      this.componentKey += 1;
    },
    async changeDateFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        let allPromises = [];

        allPromises.push(
          this.$store.dispatch("GETPOS", {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
            warehouses: [],
          })
        );

        await Promise.all(allPromises);

        this.forceRerender();
      }
    },
  },
  watch: {},
  computed: {
    pos() {
      return this.$store.getters.POS.map((singlePos) => {
        const posWithProps = { ...singlePos };
        return posWithProps;
      });
    },
    selectedPosInformation() {
      for (const singlePos of this.pos) {
        if (singlePos._id === this.posInformation._id) {
          return singlePos;
        }
      }
      return this.selectedPosInformation;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
