<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Abrir caja con saldo inicial</h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Saldo inicial (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': posOpenRequiredErrors.SALDO_INICIAL }"
        :message="{
          'El saldo inicial no es válido': posOpenRequiredErrors.SALDO_INICIAL,
        }"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 390"
          icon="cash"
          v-model="posOpen.SALDO_INICIAL"
          :required="true"
          :disabled="false"
        />
      </b-field>
    </b-field>

    <h3 class="form-division">Cambio</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de billetes de 1000 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="posOpen.CAMBIO_INICIAL.MIL"
          type="number"
          step="any"
          @input="updtateInitialBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de billetes de 500 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="posOpen.CAMBIO_INICIAL.QUINIENTOS"
          type="number"
          step="any"
          @input="updtateInitialBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de billetes de 200 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="posOpen.CAMBIO_INICIAL.DOSCIENTOS"
          type="number"
          step="any"
          @input="updtateInitialBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de billetes de 100 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="posOpen.CAMBIO_INICIAL.CIEN"
          type="number"
          step="any"
          @input="updtateInitialBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de billetes de 50 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="posOpen.CAMBIO_INICIAL.CINCUENTA"
          type="number"
          step="any"
          @input="updtateInitialBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de billetes de 20 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="posOpen.CAMBIO_INICIAL.VEINTE"
          type="number"
          step="any"
          @input="updtateInitialBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de monedas de 10 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="posOpen.CAMBIO_INICIAL.DIEZ"
          type="number"
          step="any"
          @input="updtateInitialBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de monedas de 5 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="posOpen.CAMBIO_INICIAL.CINCO"
          type="number"
          step="any"
          @input="updtateInitialBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de monedas de 2 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="posOpen.CAMBIO_INICIAL.DOS"
          type="number"
          step="any"
          @input="updtateInitialBalance()"
        ></b-input>
      </b-field>

      <b-field
        label="Cantidad de monedas de 1 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="posOpen.CAMBIO_INICIAL.UNO"
          type="number"
          step="any"
          @input="updtateInitialBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de monedas de 0.50 (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 5"
          expanded
          icon="cash"
          v-model="posOpen.CAMBIO_INICIAL.CINCUENTA_CEN"
          type="number"
          step="any"
          @input="updtateInitialBalance()"
        ></b-input>
      </b-field>
    </b-field>

    <div class="add-global-controls">
      <b-button type="is-success" @click="openPos()"
        >Abrir caja con saldo inicial</b-button
      >
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../utils/fns";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";

// @ is an alias to /src
export default {
  name: "PosOpenCaja",
  components: {},
  props: ["posInformation"],
  directives: { cleave },
  data() {
    return {
      typeSelected: [],
      posOpen: {
        SALDO_INICIAL: 0,
        CAMBIO_INICIAL: {
          MIL: 0,
          QUINIENTOS: 0,
          DOSCIENTOS: 0,
          CIEN: 0,
          CINCUENTA: 0,
          VEINTE: 0,
          DIEZ: 0,
          CINCO: 0,
          DOS: 0,
          UNO: 0,
          CINCUENTA_CEN: 0,
        },
      },
      posOpenRequiredErrors: {
        SALDO_INICIAL: false,
      },
    };
  },
  mounted() {
    if (this.posInformation._id) {
      this.posOpen._id = this.posInformation._id;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves or updates brand in the database depending on type
     */
    async openPos(type) {
      try {
        this.posOpenRequiredErrors = validateFields(
          this.posOpen,
          this.posOpenRequiredErrors
        );
        if (checkValidationErrors(this.posOpenRequiredErrors)) {
          this.validateNullValues();
          let response = await this.$store.dispatch("OPENPOS", this.posOpen);
          if (response == "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se abrió exitosamente la caja",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al abrir la caja en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al abrir la caja en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al abrir la caja en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    updtateInitialBalance() {
      let finalInitialBalance = 0;

      this.posOpen.CAMBIO_INICIAL.MIL = parseFloat(
        this.posOpen.CAMBIO_INICIAL.MIL
      );

      this.posOpen.CAMBIO_INICIAL.QUINIENTOS = parseFloat(
        this.posOpen.CAMBIO_INICIAL.QUINIENTOS
      );

      this.posOpen.CAMBIO_INICIAL.DOSCIENTOS = parseFloat(
        this.posOpen.CAMBIO_INICIAL.DOSCIENTOS
      );

      this.posOpen.CAMBIO_INICIAL.CIEN = parseFloat(
        this.posOpen.CAMBIO_INICIAL.CIEN
      );

      this.posOpen.CAMBIO_INICIAL.CINCUENTA = parseFloat(
        this.posOpen.CAMBIO_INICIAL.CINCUENTA
      );

      this.posOpen.CAMBIO_INICIAL.VEINTE = parseFloat(
        this.posOpen.CAMBIO_INICIAL.VEINTE
      );

      this.posOpen.CAMBIO_INICIAL.DIEZ = parseFloat(
        this.posOpen.CAMBIO_INICIAL.DIEZ
      );

      this.posOpen.CAMBIO_INICIAL.CINCO = parseFloat(
        this.posOpen.CAMBIO_INICIAL.CINCO
      );

      this.posOpen.CAMBIO_INICIAL.DOS = parseFloat(
        this.posOpen.CAMBIO_INICIAL.DOS
      );

      this.posOpen.CAMBIO_INICIAL.UNO = parseFloat(
        this.posOpen.CAMBIO_INICIAL.UNO
      );

      this.posOpen.CAMBIO_INICIAL.CINCUENTA_CEN = parseFloat(
        this.posOpen.CAMBIO_INICIAL.CINCUENTA_CEN
      );

      finalInitialBalance += this.posOpen.CAMBIO_INICIAL.MIL * 1000;
      finalInitialBalance +=
        parseFloat(this.posOpen.CAMBIO_INICIAL.QUINIENTOS) * 500;
      finalInitialBalance += this.posOpen.CAMBIO_INICIAL.DOSCIENTOS * 200;
      finalInitialBalance += this.posOpen.CAMBIO_INICIAL.CIEN * 100;
      finalInitialBalance += this.posOpen.CAMBIO_INICIAL.CINCUENTA * 50;
      finalInitialBalance += this.posOpen.CAMBIO_INICIAL.VEINTE * 20;
      finalInitialBalance += this.posOpen.CAMBIO_INICIAL.DIEZ * 10;
      finalInitialBalance += this.posOpen.CAMBIO_INICIAL.CINCO * 5;
      finalInitialBalance += this.posOpen.CAMBIO_INICIAL.DOS * 2;
      finalInitialBalance += this.posOpen.CAMBIO_INICIAL.UNO * 1;
      finalInitialBalance += this.posOpen.CAMBIO_INICIAL.CINCUENTA_CEN * 0.5;

      this.posOpen.SALDO_INICIAL = finalInitialBalance;
    },
    validateNullValues() {
      if (!this.posOpen.CAMBIO_INICIAL.MIL) {
        this.posOpen.CAMBIO_INICIAL.MIL = 0;
      }

      if (!this.posOpen.CAMBIO_INICIAL.QUINIENTOS) {
        this.posOpen.CAMBIO_INICIAL.QUINIENTOS = 0;
      }

      if (!this.posOpen.CAMBIO_INICIAL.DOSCIENTOS) {
        this.posOpen.CAMBIO_INICIAL.DOSCIENTOS = 0;
      }

      if (!this.posOpen.CAMBIO_INICIAL.CIEN) {
        this.posOpen.CAMBIO_INICIAL.CIEN = 0;
      }

      if (!this.posOpen.CAMBIO_INICIAL.CINCUENTA) {
        this.posOpen.CAMBIO_INICIAL.CINCUENTA = 0;
      }

      if (!this.posOpen.CAMBIO_INICIAL.VEINTE) {
        this.posOpen.CAMBIO_INICIAL.VEINTE = 0;
      }

      if (!this.posOpen.CAMBIO_INICIAL.DIEZ) {
        this.posOpen.CAMBIO_INICIAL.DIEZ = 0;
      }

      if (!this.posOpen.CAMBIO_INICIAL.CINCO) {
        this.posOpen.CAMBIO_INICIAL.CINCO = 0;
      }

      if (!this.posOpen.CAMBIO_INICIAL.DOS) {
        this.posOpen.CAMBIO_INICIAL.DOS = 0;
      }

      if (!this.posOpen.CAMBIO_INICIAL.UNO) {
        this.posOpen.CAMBIO_INICIAL.UNO = 0;
      }

      if (!this.posOpen.CAMBIO_INICIAL.CINCUENTA_CEN) {
        this.posOpen.CAMBIO_INICIAL.CINCUENTA_CEN = 0;
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
