<template>
  <div class="container global-list-main-container">
    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns-inner">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="table-header">
      Cantidad de cortes: <b>{{ cortesCaja.length }}</b>
    </div>
    <b-table
      pagination-position="both"
      :data="cortesCaja"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      scrollable
      ref="CortesCaja"
      :checked-rows.sync="checkedChanges"
      paginated
      :current-page="currentPage"
      :per-page="perPage"
      :selected.sync="selected"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="asc"
      default-sort="APERTURA_FOR"
      detailed
      detail-key="_id"
      checkbox-position="left"
      @details-open="
        (row) =>
          $buefy.toast.open({
            message: `Información de ${row.APERTURA_FOR}`,
            type: 'is-success',
            position: 'is-bottom',
          })
      "
    >
      <template #empty>
        <div class="has-text-centered">No hay cortes</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          searchable
          sortable
          :visible="column.display"
          v-bind="column"
          :key="column.field"
          :label="column.label"
          :custom-sort="column.customSort"
        >
          <span
            v-if="!checkIfValid(props.row[column.field])"
            v-bind:class="{ 'red-cell': props.row.STATUS === 'NA' }"
            >{{ "Sin información" }}</span
          >
          <span
            v-if="checkIfValid(props.row[column.field]) && column.money"
            v-bind:class="{ 'red-cell': props.row.STATUS === 'NA' }"
            >{{ props.row[column.field] | money("MXN", 2) }}</span
          >
          <span
            v-if="checkIfValid(props.row[column.field]) && !column.money"
            v-bind:class="{ 'red-cell': props.row.STATUS === 'NA' }"
          >
            {{ props.row[column.field] }}</span
          >
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64"></p>
          </figure>
          <div class="media-content">
            <div class="content">
              <div class="controls">
                <b-button
                  type="is-primary"
                  @click="openCloseCorte(props.row)"
                  v-if="props.row.STATUS === 'AB'"
                  >Cerrar corte</b-button
                >

                <b-button
                  type="is-primary"
                  @click="openCorte(props.row)"
                  v-if="canBeReopened(props.row)"
                  >Abrir corte nuevamente</b-button
                >

                <b-button
                  type="is-primary"
                  @click="authorizeCorte(props.row)"
                  v-if="props.row.STATUS === 'PA'"
                  >Autorizar corte</b-button
                >

                <b-button
                  type="is-primary"
                  @click="noAuthorizeCorte(props.row)"
                  v-if="props.row.STATUS === 'PA'"
                  >No autorizar corte</b-button
                >

                <b-button
                  type="is-primary"
                  @click="openCorteDetails(props.row)"
                  v-if="props.row.STATUS !== 'AB'"
                  >Ver detalles de corte</b-button
                >

                <b-button type="is-primary" @click="openCorteSales(props.row)"
                  >Ver ventas realizadas</b-button
                >

                <b-button
                  type="is-primary"
                  @click="openManualMovements(props.row)"
                  >Ver movimientos manuales realizados</b-button
                >
              </div>
            </div>
          </div>
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import CloseCorteModal from "./CloseCorteModal";
import CloseCorteDetailsModal from "./CloseCorteDetailsModal";
import PosSaleList from "./PosSaleList";
import ManualMovementsList from "./ManualMovementsList";
import { EventBus } from "../../../event-bus.js";

const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

export default {
  name: "PosCortesList",
  props: ["information"],
  components: {},
  directives: { cleave },
  data() {
    //warehouses
    return {
      perPage: 50,
      checkedChanges: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "USUARIO_APERTURA",
          label: "Usuario de apertura",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "USUARIO_CIERRE",
          label: "Usuario de cierre",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "APERTURA_FOR",
          label: "Fecha de apertura",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDateOpen,
        },
        {
          field: "CIERRE_FOR",
          label: "Fecha de cierre",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDateClose,
          money: false,
        },
        {
          field: "STATUS_FOR",
          label: "Status",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "SALDO_INICIAL",
          label: "Saldo inicial",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "SALDO_FINAL",
          label: "Saldo final",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "VENTAS_EFECTIVO_FOR",
          label: "Venta efectivo",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "VENTAS_EFECTIVO_SC_FOR",
          label: "Venta efectivo no canceladas",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "BANCARIOS_FOR",
          label: "Venta movimientos bancarios",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "CREDITOS_FOR",
          label: "Venta Créditos",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "MANUALES_FOR",
          label: "Movimientos manuales",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "DEVOLVER_FOR",
          label: "Cantidad a devolver",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "DIFERENCIA_TOTAL_FOR",
          label: "Corte",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
      ],
      masks: Masks,
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {},
  methods: {
    formatedDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    sortByDateOpen(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.APERTURA).getTime() || -Infinity) -
          (new Date(a.APERTURA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.APERTURA).getTime() || -Infinity) -
          (new Date(b.APERTURA).getTime() || -Infinity)
        );
      }
    },
    sortByDateClose(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.CIERRE).getTime() || -Infinity) -
          (new Date(a.CIERRE).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.CIERRE).getTime() || -Infinity) -
          (new Date(b.CIERRE).getTime() || -Infinity)
        );
      }
    },
    openCloseCorte(corteInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: CloseCorteModal,
        props: {
          posInformation: this.information,
          corteInformation: corteInformation,
          type: 0,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async authorizeCorte(corteInformation) {
      try {
        let response = await this.$store.dispatch("AUTHORIZECORTE", {
          corteId: corteInformation._id,
          posId: this.information._id,
        });
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se guardó exitosamente la autorización del corte",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              EventBus.$emit("closeCorteModalRefresh", {});
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la autorización del corte en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar la autorización del corte en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async noAuthorizeCorte(corteInformation) {
      try {
        let response = await this.$store.dispatch("NOAUTHORIZECORTE", {
          corteId: corteInformation._id,
          posId: this.information._id,
        });
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message:
              "Se guardó exitosamente la denegación de la autorización del corte",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              EventBus.$emit("closeCorteModalRefresh", {});
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la denegación de a autorización del corte en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar la la denegación de a autorización del corte en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    openCorteDetails(corteInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: CloseCorteDetailsModal,
        props: {
          posInformation: this.information,
          corteInformation: corteInformation,
          type: 1,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openCorteSales(corteInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: PosSaleList,
        props: {
          salesList: corteInformation.VENTAS,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async openCorte(corteInformation) {
      try {
        let response = await this.$store.dispatch("OPENAGAINPOS", {
          posId: this.information._id,
        });
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se abrió de nuevo el corte exitosamente ",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              EventBus.$emit("closeCorteModalRefresh", {});
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al abrir de nuevo el corte en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al abrir de nuevo el corte en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    openManualMovements(corteInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ManualMovementsList,
        props: {
          movementsList: corteInformation.MOVIMIENTOS_MANUALES,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    canBeReopened(corteInformation) {
      return (
        corteInformation.STATUS === "PA" &&
        corteInformation._id ===
          this.information.CORTES[this.information.CORTES.length - 1]._id
      );
    },
  },
  watch: {
    /**
     * @desc Select default opened details, to open a specific warehouse
     */
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    cortesCaja() {
      return this.information.CORTES.map((singleCorte) => {
        const corteWithProps = { ...singleCorte };

        let cashCancellationsAmount = 0;
        for (const singleMovement of singleCorte.MOVIMIENTOS_MANUALES) {
          if (
            singleMovement &&
            singleMovement.DESCRIPCION &&
            singleMovement.DESCRIPCION.includes(
              "Cancelación de venta con folio"
            )
          ) {
            cashCancellationsAmount += singleMovement.CANTIDAD;
          }
        }

        corteWithProps.VENTAS_EFECTIVO_FOR = singleCorte.RECIBIDO.EFECTIVO;
        corteWithProps.VENTAS_EFECTIVO_SC_FOR =
          singleCorte.RECIBIDO.EFECTIVO + cashCancellationsAmount;
        corteWithProps.BANCARIOS_FOR =
          singleCorte.RECIBIDO.CHEQUE +
          singleCorte.RECIBIDO.TRANSFERENCIAS +
          singleCorte.RECIBIDO.TARJETA_DE_CREDITO +
          singleCorte.RECIBIDO.TARJETA_DE_DEBITO;
        corteWithProps.CREDITOS_FOR = singleCorte.RECIBIDO.CREDITO;
        corteWithProps.MANUALES_FOR = singleCorte.RECIBIDO.MANUALES;

        corteWithProps.DEVOLVER_FOR =
          singleCorte.RECIBIDO.EFECTIVO +
          singleCorte.RECIBIDO.MANUALES +
          singleCorte.SALDO_INICIAL;

        corteWithProps.DIFERENCIA_TOTAL_FOR =
          (singleCorte.DIFERENCIA_TOTAL + singleCorte.SALDO_INICIAL) * -1;

        if (singleCorte.STATUS === "AB") {
          corteWithProps.STATUS_FOR = "Abierto";
        }

        if (singleCorte.STATUS === "PA") {
          corteWithProps.STATUS_FOR = "Pendiente autorización";
        }

        if (singleCorte.STATUS === "AU") {
          corteWithProps.STATUS_FOR = "Autorizado";
        }

        if (singleCorte.STATUS === "NA") {
          corteWithProps.STATUS_FOR = "No autorizado";
        }

        if (singleCorte.APERTURA) {
          corteWithProps.APERTURA_FOR = this.formatedDate(singleCorte.APERTURA);
        }

        if (singleCorte.CIERRE) {
          corteWithProps.CIERRE_FOR = this.formatedDate(singleCorte.CIERRE);
        }

        return corteWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
