<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!secretInformation">
      Agregar secreto
    </h1>

    <div v-if="!secretInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Descripción del secreto (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': secretRequiredErrors.DESCRIPCION }"
        :message="{
          'La descripción del secreto no es válida':
            secretRequiredErrors.DESCRIPCION,
        }"
      >
        <b-input
          placeholder="Ejemplo: Lista de precios mayoreo"
          expanded
          icon="numeric"
          v-model="newSecret.DESCRIPCION"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Secreto (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': secretRequiredErrors.SECRETO }"
        :message="{
          'El secreto no es válido': secretRequiredErrors.SECRETO,
        }"
      >
        <b-input
          placeholder="Ejemplo: 1234MAYOREO_2SO21"
          expanded
          icon="form-textbox-password"
          v-model="newSecret.SECRETO"
          type="password"
          password-reveal
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Lista de precios (Requerido)"
        expanded
        label-position="inside"
        required
        :type="{ 'is-danger': secretRequiredErrors.LISTA_PRECIOS }"
        :message="{
          'La lista de precios del secreto no es valida':
            secretRequiredErrors.LISTA_PRECIOS,
        }"
        class="required-input"
      >
        <b-select
          placeholder="Ejemplo: Martillo Almacen general"
          expanded
          icon="format-list-bulleted"
          v-model="newSecret.LISTA_PRECIOS"
        >
          <option value="" disabled>Seleccione una lista de precios</option>
          <option
            v-for="singlePriceList in priceLists"
            :value="singlePriceList._id"
            :key="singlePriceList.CLAVE_LISTAP"
          >
            {{ singlePriceList.CLAVE_LISTAP }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <div class="add-global-controls" v-if="secretInformation">
      <b-button type="is-success" @click="saveSecret(1)"
        >Actualizar secreto</b-button
      >
    </div>

    <div class="add-global-controls" v-if="!secretInformation">
      <b-button type="is-success" @click="saveSecret(0)"
        >Añadir secreto</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../utils/fns";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";
import { fillObjectForm } from "../../../utils/component-fns";
// @ is an alias to /src
export default {
  name: "AddSecretModal",
  props: ["secretInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      typeSelected: [],
      newSecret: {
        DESCRIPCION: "",
        LISTA_PRECIOS: "",
        SECRETO: "",
      },
      secretRequiredErrors: {
        DESCRIPCION: false,
        LISTA_PRECIOS: false,
        SECRETO: false,
      },
    };
  },
  mounted() {
    // Fill the brand information in form fields
    if (this.secretInformation) {
      // Fill the form with received information
      fillObjectForm(this.secretInformation, this.newSecret);
      this.newSecret._id = this.secretInformation._id;
      this.newSecret.LISTA_PRECIOS = this.secretInformation.LISTA_PRECIOS._id;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves or updates brand in the database depending on type
     */
    async saveSecret(type) {
      try {
        this.secretRequiredErrors = validateFields(
          this.newSecret,
          this.secretRequiredErrors
        );
        if (checkValidationErrors(this.secretRequiredErrors)) {
          let response = await this.$store.dispatch(
            type === 0 ? "SAVESECRET" : "EDITSECRET",
            {
              secretObject: this.newSecret,
            }
          );
          if (response == "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el secreto",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                if (type === 0) this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar el secreto en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el secreto en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar el secreto en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {
    priceLists() {
      return this.$store.getters.PRICES_LISTS.map((singlePriceList) => {
        let singlePriceListWithProps = { ...singlePriceList };
        return singlePriceListWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
